import { defineStore } from "pinia";
import { reactive, ref } from "vue";

interface cacheItem {
  data: string;
  date: number;
}

export const useCacheStore = defineStore("CacheStore", () => {
  const cache = new Map<string, cacheItem>();
  const longCacheUrls = ["/users/profile", "/sessions/sessions", "/info/price"];
  const expireDate = (longCache = false) => {
    const d = new Date();
    d.setSeconds(d.getSeconds() + (longCache ? 250 : 35));
    return d.getTime();
  };
  const add = (url, content) => {
    cache.set(url, {
      data: content,
      date: expireDate(longCacheUrls.includes(url)),
    });
  };
  const get = (url) => {
    if (cache.has(url)) {
      const d = new Date();
      const data = cache.get(url);
      if (data.date <= d.getTime()) {
        console.log("cache deleted");
        cache.delete(url);
        return null;
      } else {
        return data.data;
      }
    } else {
      return null;
    }
  };
  const deleteCache = (url)=>{
    if (cache.has(url)) {
      cache.delete(url);
    }
  }
  return {
    add,
    get,
    deleteCache
  };
});
