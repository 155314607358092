<template>
  <div class="big-display" :class="{iossafezone:isIOS && $route.name !== 'auth-index'}">
    <cap-push v-if="capMacro.isAndroid()"/>
    <install-pwa/>
    <android-pay-check/>
    <goftino/>
    <component class="body-main" :is="$route.meta.layout || 'div'">
      <RouterView />
    </component>
    <google-auth-modal />
    <red-toast v-model:text="cmonStore.getRedToast.title" v-model:timeout="cmonStore.getRedToast.timeout" />
    <green-toast v-model:text="cmonStore.getGreenToast.title" v-model:timeout="cmonStore.getGreenToast.timeout" />
    <by-cott-modal/>
    <popup-push-modal/>
  </div>
</template>
<script setup>

import {onMounted, provide, ref} from "vue";

import GoogleAuthModal from "@/components/embeded/googleAuthModal.vue";
import RedToast from "@/components/embeded/redToast.vue";
import { useCommonStore } from "@/stores/useCommonStore";
import GreenToast from "@/components/embeded/greenToast.vue";
import InstallPwa from "@/components/embeded/installPwa.vue";
import Goftino from "@/components/goftino.vue";
import ByCottModal from "@/components/embeded/byCottModal.vue";
import { useBackButton } from '@ionic/vue';
const cmonStore = useCommonStore();
import { StatusBar, Style } from '@capacitor/status-bar';
import { Capacitor } from '@capacitor/core';

import AndroidPayCheck from "@/components/AndroidPayCheck.vue";
import PopupPushModal from "@/components/embeded/popupPushModal.vue";
import CapasitorMacro from "@/composables/deepLogic/CapasitorMacro";
import CapPush from "@/components/capPush.vue";
const isIOS = ref(false);
const capMacro = CapasitorMacro();
useBackButton(10, () => {
  history.back();
});
onMounted(() => {
  const e = localStorage.getItem("theme") || "",
    a = window.matchMedia("(prefers-color-scheme: dark)").matches;
  (!e || e === "auto" ? a : e === "dark") && document.documentElement.classList.add("dark");


  if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
    window.document.addEventListener('touchmove', e => {
      if(e.scale !== 1) {
        e.preventDefault();
      }
    }, {passive: false});
  }
  if(capMacro.isAndroid()){
    StatusBar.setStyle({ style: Style.Dark })
    StatusBar.setBackgroundColor({
      color:'#3F68FD'
    })

  }
  isIOS.value = Capacitor.getPlatform().toLowerCase() === 'ios';
});

</script>
<style>
.iossafezone{
  padding-top: constant(safe-area-inset-top) !important;
  padding-top: env(safe-area-inset-top) !important;
}
/*.iossafezone{*/
/*  padding-top:46px !important;*/
/*}*/
.iossafezone  .ioscurvyHeader{
  padding-top:52px !important;
}
.iossafezone .iosBody{
  padding-top: 1rem !important;
}
.curvyHeader a{
  top: 0;
}
.iossafezone  .ioscurvyHeader a{
  top: 30px;
}
#goftino_w{
  padding-top: constant(safe-area-inset-top) !important;
  padding-top: env(safe-area-inset-top) !important;
}
@media (min-width: 900px){
  .big-display{
    /*width: 100%;*/
    /*position: absolute;*/
    width: 100%;
    position: absolute;
    max-width: 540px;
    left: 50%;
    transform: translateX(-50%) scale(1.3);
    top: 12%;
    bottom: 11%;
  }
  .curvyHeader,.navigation{
    max-width: 100%;
  }
}
</style>