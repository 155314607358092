import { useCommonStore } from "@/stores/useCommonStore";

interface CustomPluginMethods {
  redAlert: (title: string, timeout?: number) => void;
  greenAlert: (title: string, timeout?: number) => void;
}
const customPlugin = {
  install(app) {
    const cmmonStorage = useCommonStore();
    app.config.globalProperties.$customPlugin = {
      redAlert(title, timeout = 5000) {
        cmmonStorage.setRedToastMessage(title, timeout);
      },
      greenAlert(title, timeout = 5000) {
        cmmonStorage.setGreenToastMessage(title, timeout);
      },
    };
  },
};

type CustomPlugin = typeof customPlugin & CustomPluginMethods;

export default customPlugin as CustomPlugin;

declare module "@vue/runtime-core" {
  interface ComponentCustomProperties {
    $customPlugin: CustomPluginMethods;
  }
}
