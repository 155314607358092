<template>
  <div class="p-2 m-auto pt-0">
    <header class="d-flex align-items-center justify-content-between py-2 mb-2">
      <h1 class="title mb-0">
        {{ $t("route_title." + $route.name) }}
      </h1>
      <p class="paraghrap date text-secondary mx-2" v-if="$route.name === 'home'">
        {{ new Date().toLocaleDateString("fa-IR") }}
      </p>
      <div>
        <router-link :to="{ name: 'notification-index' }" class="position-relative">
          <img src="../assets/images/svg/notification.svg" alt="icon" class="mx-2" />
          <span class="counter"> {{ userDashboardData?.unseen_msg_count }} </span>
        </router-link>
        <img src="../assets/images/svg/moon.svg" alt="icon" ref="themeIcon" id="theme" @click="changeTheme" />
      </div>
    </header>
    <main>
      <RouterView v-slot="{ Component }">
        <transition name="scale" mode="out-in">
          <suspense timeout="0">
            <template #default>
              <component :is="Component" />
            </template>
            <template #fallback>
              <div>
                <index-loading />
              </div>
            </template>
          </suspense>
        </transition>
      </RouterView>
    </main>
    <UserNavigator />
  </div>
</template>

<script lang="ts">
import { computed, ref } from "vue";
import UserNavigator from "@/components/UserNavigator.vue";
import IndexLoading from "@/views/loadings/index-loading.vue";
import { storeToRefs } from "pinia";
import useUserDataStore from "@/stores/useUserDataStore";

export default {
  name: "mainLayout",
  components: { IndexLoading, UserNavigator },
  setup() {
    const theme = localStorage.getItem("theme");
    const themeIcon = ref();
    const addDarkMode = () => {
      localStorage.setItem("theme", "dark");
      document.documentElement.classList.add("dark");
      if (themeIcon) themeIcon.src = "./src/images/svg/sun.svg";
    };
    const removeDarkMode = () => {
      localStorage.setItem("theme", "light");
      document.documentElement.classList.remove("dark");
      if (themeIcon) themeIcon.src = "./src/images/svg/moon.svg";
    };
    const changeTheme = () => {
      let currentTheme = localStorage.getItem("theme");
      if (currentTheme === "dark") {
        removeDarkMode();
      } else {
        addDarkMode();
      }
    };
    const { userDashboardData } = storeToRefs(useUserDataStore().useDashboardStore());
    const msgcount = computed(() => {
      try {
        return userDashboardData.unseen_msg_count;
      } catch (e) {
        return 0;
      }
    });
    return {
      themeIcon,
      changeTheme,
      msgcount,
      userDashboardData,
    };
  },
  mounted() {},
};
</script>

<style scoped>
main {
  margin-bottom: 4rem;
}
.scale-enter-active,
.scale-leave-active {
  transition: all 0.5s ease;
}

.scale-enter-from,
.scale-leave-to {
  opacity: 0;
}
</style>
