<template>

</template>

<script>
import {getCurrentInstance, onMounted} from "vue";
import {useI18n} from "vue-i18n";

export default {
  name: "goftino",
  setup(){
    const {t} = useI18n();
    onMounted(()=>{
      !function(){var i="VZJprk",a=window,d=document;function g(){var g=d.createElement("script"),s="https://www.goftino.com/widget/"+i,l=localStorage.getItem("goftino_"+i);g.async=!0,g.src=l?s+"?o="+l:s;d.getElementsByTagName("head")[0].appendChild(g);}"complete"===d.readyState?g():a.attachEvent?a.attachEvent("onload",g):a.addEventListener("load",g,!1);}();
      window.addEventListener('goftino_ready', function () {
        Goftino.setWidget({
          iconUrl: '',
          cssUrl: '',
          soundUrl: '',
          marginRight: 0,
          marginLeft: 0,
          marginBottom: 0,
          hasIcon: false,
          hasSound: false,
          counter: '#div_idName'  //or '.div_className'
        });
        window.addEventListener('goftino_getMessage', function (d) {
          Goftino.open();
        });
      });
    })
  }
}
</script>

<style scoped>

</style>