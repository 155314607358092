import {MehranBitDepositState, MehranBitWithdrawal} from "@/types/signed/logics";

export interface FactorBuyCrypto {
  id: number;
  currency_id: number;
  currency_name: string;
  txId: string;
  desAddress: string;
  desMemoTag: string;
  orderType: string;
  symbolPrice: string;
  wdState: number;
  errStatus: number;
  usdtAmount: number;
  cryptoAmount: number;
  cn_id: number;
  cn_minwd: string;
  cn_minfee: string;
  dollarPrice: number;
  symbolService: string;
  factorStatus: string;
  factorCryptoSteps: number;
  factorDate: number;
  factorType: string;
  walletaddress: string;
  tagmemo: string;
  dollarts: number;
  logicstate: string;
  fee: number;
  orderPriceToman: number;
  feeToman: number;
  factorStatusInt: number;
  factorCryptoStepsStr: string;
  factorTypeInt: number;
  ts: number;
  logicstateInt: MehranBitWithdrawal;
  tradeFee:number;
}

export interface FactorOnlinePayment {
  fid: number;
  baseprice_usd: number;
  baseprice_euro: number;
  url: string;
  username: string;
  password: string;
  description: string;
  picture: string;
  amount: number;
  paymethod: number;
  currency: string;
  admin_description: string;
  admin_id: number;
  ways_en: string;
  ways_fa: string;
  fee: number;
  factor_date: number;
  factorStatus: string;
  factorStep: string;
}
export interface FactorPerfectMoney {
  fid: number;
  mode: string;
  amount: string;
  batchnum: any;
  extradata: string;
  factor_date: number;
  dollarPrice: number;
  factorStatusInt:number;
}
export interface FactorSellCrypto {
  factor_id: number
  symbol: any
  factor_status: FactorStatusStr
  factor_step: any
  deposit_state: MehranBitDepositState
  logic_state: any
  ccy_amount: number
  usdt_amount: number
  deposit_network: any
  currencyPrice: any
  depid: any
  tx_id: any
  status: number
  status_msg: string
  rialCharged: any
  submittedDate: number
}

export interface FactorInterfaces extends FactorBuyCrypto, FactorOnlinePayment, FactorPerfectMoney,FactorSellCrypto {}

export interface FactorInterfaceObject<T> {
  factor:T
}

export enum FactorCryptoSteps {
  //---------------for buy
  TransferFundsToSubAcc = 0,
  CheckTransferSuccess = 1,
  Exchange_Trade = 2,
  CheckTradeState = 3,
  TransferFundsToMainAcc = 4,
  CheckTransferSuccess2 = 5,
  TransferTradingAccToFundingAccUSDT = 6,
  withdrawal = 7,
  withdrawalComplete = 8,
  //--------------for sell - 8
  ReceivingFunds = 9,
  ExchangeToUSDT = 10,
  CheckTradeStateSell = 11,
  SellCompleted = 12,
  //-------------- for Ghabzino
  GhabzinoComplete = 13,
  //------------- for perfectmoney
  PerfectMoneyNotPaid = 14,
  PerfectMoneyComplete = 15,
  //------------- for online Payment
  OnlinePaymentNotPaid = 16,
  OnlinePlaymentOrdered = 17,
  GiftCardOrdered = 18,
}
export enum FactorStatus {
  //for buy
  NOT_PAID = 0,
  PAID_PEINDING = 1,
  WITHDRAW = 2,
  SENT = 3,
  // for sell
  Receiving_Pending = 4,
  Exchanging = 5,
  RialWalletCharged = 6,
  REJECTED = 7,
  CanceledByUser = 8,
  // for ghabzino
  GhabzinoPaid = 9,
  GhabzinoFailed = 10,
  GhabzinoPending = 11,
  // for perfectmoney;
  PerfectMoneyBuyPending = 12,
  PerfectMoneyBuyComplete = 13,
  PerfectMoneySellComplete = 14,
  // for online payment
  OnlinePaymentPending = 15,
  OnlinePaymentRejectedbyAdmin = 16,
  OnlinePaymentPaidbyAdmin = 17,
  //gift card
  GiftCardComplete = 18,
  factor_timeout = 19,
}
export enum FactorStatusStr {
  //for buy
  NOT_PAID = "NOT_PAID",
  PAID_PEINDING = "PAID_PEINDING",
  WITHDRAW = "WITHDRAW",
  SENT = "SENT",
  // for sell
    Receiving_Pending = "Receiving_Pending",
  Exchanging = "Exchanging",
  RialWalletCharged = "RialWalletCharged",
  REJECTED = "REJECTED",
  CanceledByUser = "CanceledByUser",
  // for ghabzino
  GhabzinoPaid = "GhabzinoPaid",
  GhabzinoFailed = "GhabzinoFailed",
  GhabzinoPending = "GhabzinoPending",
  // for perfectmoney;
  PerfectMoneyBuyPending = "PerfectMoneyBuyPending",
  PerfectMoneyBuyComplete = "PerfectMoneyBuyComplete",
  PerfectMoneySellComplete = "PerfectMoneySellComplete",
  // for online payment
  OnlinePaymentPending = "OnlinePaymentPending",
  OnlinePaymentRejectedbyAdmin = "OnlinePaymentRejectedbyAdmin",
  OnlinePaymentPaidbyAdmin = "OnlinePaymentPaidbyAdmin",
  //gift card
  GiftCardComplete = "GiftCardComplete",
  factor_timeout = "factor_timeout",
}

export enum FactorTypes {
  NULL = 0,
  BUY_CRYPTO = 1,
  SELL_CRYPTO = 2,
  BUY_PERFECTMONEY = 3,
  SELL_PERFECTMONEY = 4,
  GHABZINO = 5,
  ONLINE_PAYMENT = 6,
  GIFTCARD = 7,
}
