//npx prettier . --write --bracket-same-line --single-attribute-per-line=false --print-width=200
import {getFingerPrint} from "@/composables/deepLogic/BrowserUtils";
import "./assets/main.css";
import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import router from "./router";
import i18n from "./composables/i18n.js";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import useRequest from "@/composables/useRequest";
import customPlugin from "./composables/plugins/customPlugin";
//import Cryptoicon from 'vue-cryptoicon';
import CryptoIcon from "vue-cryptoicon/src/components/Cryptoicon.vue";
import { mbitWebsocket } from "@/composables/mbitWebsocket";
//diractives
import formatNumber from "./directives/formatNumber.js";
import cnum from "./directives/cnum.js";
const app = createApp(App);
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
app.use(pinia);
app.use(i18n);
app.use(router);
app.provide("t", i18n.global.t);
app.provide("test","pouya");
const request = useRequest;

app.use(customPlugin);
import icons from "vue-cryptoicon/src/icons";
CryptoIcon.add(icons);
app.component("CryptoIcon", CryptoIcon);
app.directive("format-number", formatNumber);
app.directive("nf", cnum);
app.provide<mbitWebsocket>("sock", new mbitWebsocket());
router.isReady().then(() => {
    getFingerPrint().then((hash)=>{
        const req = new request.Request(hash);
        app.provide<useRequest.Request>("http", req);
        app.mount('#app');
    }).catch((e)=>{
        console.log(e);
        alert("error Load App");
    })
});
import { registerSW } from 'virtual:pwa-register'

try{
    const updateSW  = registerSW({
        onRegistered(r) {
            //alert("registred");
            updateSW();
        },
        onNeedRefresh() {
            const confirmation = window.confirm(i18n.global.t('newVersionUpdate'));
            if (confirmation) {
                // Refresh the page to activate the new service worker
                updateSW();
            }
        },
        onRegisterError(e){
            console.error("-------error register sw---------");
            console.error(e);

        }
    })
}catch (e) {

}