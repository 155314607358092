import { defineStore } from "pinia";
import { ref } from "vue";
import type { carDataInterface } from "@/types/signed/Bills";
import type { CarViolationData } from "@/types/signed/Bills";
export const useCarViolationStore = defineStore(
  "carViolationStore",
  () => {
    const carData = ref<carDataInterface>({
      view_mode: "all",
      plate_left: "",
    } as carDataInterface);
    const violationData = ref<CarViolationData>({});
    return {
      carData,
      violationData,
    };
  },
  {
    persist: true,
  },
);
