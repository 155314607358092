import { defineStore } from "pinia";
import { ref } from "vue";
import type { carDataInterface } from "@/types/signed/Bills";
export const usevalidationErrorStore = defineStore(
  "validationErrorStore",
  () => {
    const errors = ref({});
    const errorFunc = (mode, key, error) => {
      if (mode === "add") errors.value[key] = error;
      else {
        delete errors.value[key];
      }
    };
    return {
      errors,
      errorFunc,
    };
  },
  {
    persist: false,
  },
);
