<template>
  <div role="alert" v-if="show " class="alert alert-info d-flex lg-text align-items-baseline justify-content-between">
    <p>
      <img src="@/assets/images/svg/tickIcon.svg" />
      {{ text }}
    </p>
    <button class="w-25 green-outline h-auto p-2 m-0" @click="closeAlert">
      {{ $t("close") }}
    </button>
  </div>
</template>

<script>
export default {
  name: "greenToast",
  props: {
    text: {
      type: String,
      default: "",
    },
    timeout: {
      type: Number,
      default: 5000,
    },
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    closeAlert() {
      this.show = false;
      this.$emit("update:text", "");
    },
  },
  watch: {
    text: {
      handler(n) {
        if (n !== "") {
          this.show = true;
          setTimeout(() => {
            this.show = false;
            this.$emit("update:text", "");
          }, this.timeout);
        }
      },
    },
  },
};
</script>

<style scoped>
.alert {
  direction: rtl;
}
</style>
