<template>
  <div class="notify" v-if="showNavNotification">
    {{$t('androidPayCheck')}}
  </div>
</template>

<script lang="ts">
import {Plugins} from "@capacitor/core";
import {onMounted, ref} from "vue";
import {storeToRefs} from "pinia/dist/pinia";
import {usePersistedCommonStore} from "@/stores/usePersistedCommonStore";
import srvRequest from "@/composables/SrvRequest";
import {InquiryGateway} from "@/types/signed/wallet";
import {useRoute, useRouter} from "vue-router";
import {useUserTokenStore} from "@/stores/useUserTokenStore";
import {
  FactorBuyCrypto,
  FactorInterfaceObject,
  FactorInterfaces,
  FactorPerfectMoney,
  FactorStatus
} from "@/types/signed/FactorDetails";

const { CapacitorPreWarm } = Plugins;
export default {
  name: "AndroidPayCheck",
  setup(){
    const http = srvRequest();
    const router = useRouter();
    const route = useRoute();
    const authStore = useUserTokenStore();
    const showNavNotification = ref(false);
    const {gatewayService} = storeToRefs(usePersistedCommonStore());
    // if (['android','web'].includes(Capacitor.getPlatform().toLowerCase())) {
    //
    // }
    document.addEventListener("visibilitychange", (ev) => {
      const sts = document["hidden"] ? "hidden" : "visible";
      if (sts === "visible") {
        checkRialDeposit();
      }
    }, false);
    const checkRialDeposit = ()=>{
      if (gatewayService.value.trackId !== 0 && authStore.isLogined() && route.name !== 'wallet-callback') {
        showNavNotification.value = true;
        const date2 = new Date();
        http.signedPost<InquiryGateway>("/payment/inquiry",{
          track_id:gatewayService.value.trackId
        }).then((res)=>{
          setTimeout(()=>{
            showNavNotification.value = false;
          },1000);
          if(res.data.status === '2'){
            gotoCallback();
          }else{
            const date1 = gatewayService.value.date;
            const timeDifference = date2.getTime() - date1;
            if(timeDifference > 30 * 60 * 1000){ //30 mins
              gatewayService.value = {
                serviceName:'',
                trackId:0
              }
            }else{
              if(!['wallet-callback','checkout'].includes(route.name) && res.data.status === '1') {
                const factorId =  gatewayService.value.factorId;
                http.signedGet<FactorInterfaceObject<FactorBuyCrypto | FactorPerfectMoney>>("/factor/get_factor/" + factorId).then((res) => {
                  if([FactorStatus.NOT_PAID,FactorStatus.PerfectMoneyBuyPending].includes(res.data.factor.factorStatusInt)){
                    router.push({name:'checkout',params:{factor_id:factorId},query:{autopay:true}});
                  }else{
                    gatewayService.value = {
                      serviceName:'',
                      trackId:0
                    }
                  }
                });
              }
            }
          }
        }).catch((e)=>{
          setTimeout(()=>{
            checkRialDeposit();
          },3000);
        });
      }
    }
    const gotoCallback = ()=>{
      if(route.name !== 'wallet-callback') {
        const queries = {
          success: '1',
          status: '2',
          trackId: gatewayService.value.trackId,
          orderId: '0'
        };
        if (gatewayService.value.factorId !== 0) {
          queries.mode = gatewayService.value.factorModule;
          queries.factor_id = gatewayService.value.factorId;
        }
        router.push({
          name: 'wallet-callback',
          query: queries
        });
      }
    }


    async function preWarmApp() {
      try {
        await CapacitorPreWarm.preWarm();
        console.log('App pre-warmed successfully');
      } catch (error) {
        console.error('Error pre-warming app:', error);
      }
    }


    onMounted(()=>{
      setTimeout(()=>{
        checkRialDeposit();
        try{
          preWarmApp();
        }catch (e) {}
      },6000);
    })
    return{
      showNavNotification,
      gotoCallback,
      gatewayService
    }
  }
}
</script>

<style scoped>
.notify{
  position: absolute;
  z-index: 999999999;
  font-size: 14px;
  padding: 5px;
  width: 100%;
  background-color: red;
}
</style>