<template>

</template>

<script>
import {PushNotifications} from "@capacitor/push-notifications";
import {onMounted} from "vue";
import CapasitorMacro from "@/composables/deepLogic/CapasitorMacro";
import {storeToRefs} from "pinia/dist/pinia";
import {usePersistedCommonStore} from "@/stores/usePersistedCommonStore";
export default {
  name: "capPush",
  setup(){
    const { popupPushNotification } = storeToRefs(usePersistedCommonStore());
    const addListeners = () => {
      PushNotifications.addListener('registration', token => {
        console.info('Registration token:', token.value);
        localStorage.setItem("fcm",token.value);
      });

      PushNotifications.addListener('registrationError', error => {
        console.error('Registration error:', error);
      });

      PushNotifications.addListener('pushNotificationReceived', notification => {
        console.log('Push notification received:', notification);
        if(notification.data.popup){
          popupPushNotification.value = {
            title:notification.title,
            body:notification.body,
            id:notification.id
          };
        }
      });

      PushNotifications.addListener('pushNotificationActionPerformed', notification => {
        console.log('Push notification action performed:', notification.actionId, notification);
        const notify = notification.notification;
        if(notify.data.popup){
          popupPushNotification.value = {
            title:notify.data.title,
            body:notify.data.body,
            id:notify.id
          };
        }
      });
    };



    const registerNotifications = async () => {
      let permStatus = await PushNotifications.checkPermissions();

      if (permStatus.receive === 'prompt') {
        permStatus = await PushNotifications.requestPermissions();
      }

      if (permStatus.receive !== 'granted') {
        throw new Error('User denied permissions!');
      }

      await PushNotifications.register();
    };

    const subScribe = async() =>{
      import('@capacitor-community/fcm').then((m) => {
        m.FCM.subscribeTo({ topic: 'all' })
      });
    }
    onMounted(() => {

      if(CapasitorMacro().isAndroid()){
        if(localStorage.getItem("fcm") == null) {
          registerNotifications()
              .then(() => {
                addListeners();
                subScribe();
              })
        }else{
          addListeners();
          subScribe();
        }
      }
    });
  }
}
</script>

<style scoped>

</style>