<template>
  <nav class="navigation d-flex align-items-center justify-content-between">
    <div v-if="isRejectedDocsViewable" class="warningAlert position-absolute start-0 end-0 py-2 px-3 xmd-paraghrap d-flex justify-content-between align-items-baseline">
      <div>
        <ul style="padding-inline-start: 15px">
          <template v-for="(auth, index) in getDashData" :key="auth.id">
            <li v-if="auth.status === 3">{{ auth.modeStr }} {{ $t("authorizationRejected") }}</li>
          </template>
        </ul>
        <router-link v-if="getULevel === 0" :to="{name:'roadmap'}" class="fw-bold view-rejected-info" data-i18n="view"> {{ $t("view") }} </router-link>
        <router-link v-else :to="{name:'levelup'}" class="fw-bold view-rejected-info" data-i18n="view"> {{ $t("view") }} </router-link>
      </div>
      <span class="h4 mb-0" role="button" @click="closeRejectedBox">X</span>
    </div>
    <router-link :class="{ active: $route.name === 'home' }" :to="{ name: 'home' }">
      <img src="../assets/images/svg/home.svg" alt="icon" />
      <span> {{ $t("home") }} </span>
    </router-link>
    <router-link :to="{ name: 'wallet_index' }" :class="{ active: $route.name === 'wallet_index' }">
      <img src="../assets/images/svg/wallet.svg" alt="icon" />
      <span> {{ $t("wallet") }} </span>
    </router-link>
    <router-link :to="{ name: 'buyandsell' }" :class="{ active: $route.name === 'buyandsell' }">
      <img src="../assets/images/svg/price.svg" alt="icon" />
      <span>{{ $t("buyAndSell") }}</span>
    </router-link>
    <router-link :to="{ name: 'market' }" :class="{ active: $route.name === 'market' }">
      <img src="../assets/images/svg/basket.svg" alt="icon" />
      <span> {{ $t("market") }} </span>
    </router-link>
    <router-link :class="{ active: $route.name === 'user_management_index' }" :to="{ name: 'user_management_index' }">
      <img src="../assets/images/svg/user.svg" alt="icon" />
      <span> {{ $t("user") }} </span>
    </router-link>
  </nav>
</template>

<script lang="ts">
import {computed, reactive, ref, watch} from "vue";
import { storeToRefs } from "pinia";
import { useRoute } from "vue-router";
import type { AuthenticatesEntity } from "@/types/signed/dashboard";
import useUserDataStore from "@/stores/useUserDataStore";
import authoriseStatus from "@/types/enums/authoriseStatus";
import {useI18n} from "vue-i18n";
export default {
  name: "UserNavigator",
  setup() {
    const route = useRoute();
    const rejected_box = ref(true);
    const {t} = useI18n();

    const dashStore = useUserDataStore().useDashboardStore();
    const {userDashboardData} = storeToRefs(dashStore);
    const closeRejectedBox = () => {
      rejected_box.value = false;
    };
    const getDashData = computed(() => {
      try{
        const authdata = userDashboardData?.value?.authenticates?.map((i) => {
          i.modeStr = t("authenticates." + i.mode);
          return i;
        });
        return authdata.filter((i)=>i.status===authoriseStatus.Rejected);
      }catch (e) {
        return [];
      }
    });
    const isRejectedDocsViewable = computed(() => {
      return route.name === "home" && rejected_box.value && ![0,undefined].includes(getDashData.value?.length);
    });
    const getULevel = computed(()=>{
      return userDashboardData.value?.user_level;
    })

    return {
      getULevel,
      getDashData,
      isRejectedDocsViewable,
      authoriseStatus,
      closeRejectedBox,
      rejected_box
    };
  },
};
</script>

<style scoped>
@import "@/assets/css/component/index.css";
</style>
