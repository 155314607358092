<template>
  <div style="margin-top: 100px">
    <div class="box"></div>
  </div>
</template>

<style scoped>
.box {
  border-radius: 8px;
  background-color: #d5d4d4;
  animation: skeletonLoading 1.5s infinite ease-in-out;
  width: 95%;
  height: 385px;
  margin: 0 auto;
  margin-bottom: 25px;
}
@keyframes skeletonLoading {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.9;
  }
  100% {
    opacity: 0.5;
  }
}
</style>
