<template>
  <div class="module levelupModule" v-if="commonStore.get2FAModalShown">
    <div class="backDrop"></div>
    <div class="p-3 module-content">
      <h6 class="lg-mainColor-text fw-bold border-bottom py-3">
        <img src="@/assets/images/svg/InfoSquare.svg" style="margin-inline-end: 5px" />
        <span data-i18n="note">{{ $t("note") }}</span>
      </h6>

      <p class="my-4 lg-text text-center fw-bold" v-html="$t('levelUpNoteDes')"></p>

      <div class="d-flex justify-content-between mt-2">
        <a @click="btnEvt('googleauthenticate')" class="mainButton w-75 ms-3">
          {{ $t("activation") }}
        </a>
        <button class="redButton-outline w-50" @click="commonStore.hideG2Fa()">
          {{ $t("willDoItLater") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, inject } from "vue";
import { useCommonStore } from "@/stores/useCommonStore";
import { useRouter } from "vue-router";

export default {
  name: "googleAuthModal",
  setup() {
    const commonStore = useCommonStore();
    const router = useRouter();
    const btnEvt = (urlName) => {
      commonStore.hideG2Fa();
      router.push({ name: urlName });
    };
    return {
      commonStore,
      btnEvt,
    };
  },
};
</script>

<style scoped>
.levelupModule {
  direction: rtl;
}
.dark .levelupModule {
  color: gray;
}
</style>
