import FingerprintJS from "@/composables/deepLogic/fingerprint";

export async function getFingerPrint() {
    let fingerPrint;
    if (localStorage.getItem("fp") == null) {
        try {
            // Load the fingerprint library asynchronously
            const fpPromise = await FingerprintJS.load();
            const result = await fpPromise.get();

            // This is the visitor identifier:
            const visitorId = result.visitorId;
            fingerPrint = visitorId;

            // Store the fingerprint in localStorage
            localStorage.setItem("fp", fingerPrint);
            return fingerPrint;
        } catch (error) {
            alert(error)
            console.error("Error loading fingerprint:", error);
        }
    } else {
        // Retrieve the fingerprint from localStorage
        fingerPrint = localStorage.getItem("fp");
        return fingerPrint;
    }
}
