import { onUnmounted } from "vue";

export class mbitWebsocket {
  private socket;
  private joinedChannels = [];
  public constructor() {
    this.connect();
  }
  private connect() {
    this.socket = new WebSocket("wss://panel.mehranbit.com/app/local?protocol=7&client=js&version=4.3.1&flash=false");

    this.socket.addEventListener("error", () => {
      //alert("error websocket");
    });
    this.socket.addEventListener("open", () => {
      console.log("WebSocket connection established");
    });
    this.socket.addEventListener("close", () => {
      console.log("WebSocket connection closed");
      setTimeout(() => {
        this.connect();
      }, 1000);
    });
  }
  public listen(channelName, callback: Function, closeUnmounted = false) {
    const subscription = () => {
      if (!this.joinedChannels.includes(channelName)) {
        this.socket.send(
          JSON.stringify({
            event: "pusher:subscribe",
            data: {
              channel: channelName,
            },
          }),
        );
      }
      console.log("WSS Subscribed : " + channelName);
      this.socket.addEventListener("message", (event) => {
        const eventData = JSON.parse(event.data);
        if (eventData.event.indexOf("pusher") === -1) {
          let chData = JSON.parse(eventData.data);
          callback(chData);
        }
      });
    };
    if (this.socket.readyState === WebSocket.OPEN) {
      subscription();
    } else {
      this.socket.addEventListener("open", subscription);
    }
    if (closeUnmounted) {
      onUnmounted(() => {
        this.unsubscribe(channelName);
        console.log("WSS unSubscribed : " + channelName);
      });
    }
  }

  public unsubscribe(channelName) {
    this.socket.send(
      JSON.stringify({
        event: "pusher:unsubscribe",
        data: {
          channel: channelName,
        },
      }),
    );
    const indexToRemove = this.joinedChannels.indexOf(channelName);
    if (indexToRemove !== -1) {
      this.joinedChannels.splice(indexToRemove, 1);
    }
  }
}
