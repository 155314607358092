import useInputValidation from "@/composables/useInputValidation";

export default {
  updated(el, binding) {
    const validator = useInputValidation();
    let parsedValue = parseFloat(validator.ConvertPersianToEnglish(el.value.toString().trim().replaceAll(",", "")));
    // alert(parsedValue);
    if (!isNaN(parsedValue)) {
      el.value = parsedValue.toLocaleString("en-US");
      binding.instance[binding.arg] = parsedValue;
    } else {
      el.value = 0;
      binding.instance[binding.arg] = 0;
    }

    // el.addEventListener('input', () => {
    //
    // });
  },
};
