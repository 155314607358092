<template>
  <div class="mx-auto d-flex flex-column justify-content-between">
    <div class="curvyHeader position-relative">
      <img class="float-start position-relative" src="../assets/images/svg/back.svg" alt="back icon" oncontextmenu="return false;" onclick="history.back()" />

      <div class="support-Tooltip position-relative d-inline-block">
        <img src="../assets/images/support.png" class="supportIcon opacity50" width="24" onclick="Goftino.toggle();" oncontextmenu="return false;" />
        <p data-i18n="needHelp">{{ $t("needHelp") }}</p>
      </div>
      <img class="m-auto position-relative d-block logo" src="../assets/images/svg/nicon_2.svg" alt="logo" />
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "authLayout1",
};
</script>

<style scoped></style>
