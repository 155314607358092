<template>
  <div class="body-main p-3 m-auto min-vh-100 d-flex flex-column justify-content-center">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "authLayout1",
};
</script>

<style scoped>
.body-main {
  background-color: var(--main-color);
}
</style>
