import { defineStore } from "pinia";

export const useCommonStore = defineStore("CommonStore", {
  state: () => ({
    modal2fa: "",
    googleHash: null,
    redToast: {
      title: "",
      timeout: 5000,
    },
    greenToast: {
      title: "",
      timeout: 5000,
    },
    ticketviewId: 0,
    openWdTimeLine: false,
    selectedNotification: {},
    b_s_crypto_title:'',
    buyandsellview:'buy',
    showUpdateAppModal:true,
    walletTMPAmount:0
  }),

  persist: false,
  getters: {
    get2FAModalShown: (state) => state.modal2fa,
    getGoogleHash: (state) => state.googleHash,
    getRedToast: (state) => state.redToast,
    getGreenToast: (state) => state.greenToast,
    getticketId: (state) => state.ticketviewId,
    getWdTimeLine: (state) => state.openWdTimeLine,
    getselectedNotification: (state) => state.selectedNotification,
    getbuyandsellview:(state)=>state.buyandsellview,
    getshowUpdateAppModal:(state)=>state.showUpdateAppModal,
    getTMPWalletAmount:(state)=>state.walletTMPAmount
  },
  actions: {
    setTMPWalletAmount(w){
      this.walletTMPAmount =w ;
    },
    setshowUpdateAppModal(s){
      this.showUpdateAppModal=s;
    },
    showG2Fa() {
      this.modal2fa = true;
    },
    hideG2Fa() {
      this.modal2fa = false;
    },
    setGoogleHash(h) {
      this.googleHash = h;
    },
    setRedToastMessage(title, timeout = 5000) {
      this.redToast = {
        title: title,
        timeout: timeout,
      };
    },
    setGreenToastMessage(title, timeout = 5000) {
      this.greenToast = {
        title: title,
        timeout: timeout,
      };
    },
    setTicketId(id) {
      this.ticketviewId = id;
    },
    OpenWdTimeLine() {
      this.openWdTimeLine = true;
    },
    CloseWdTimeLine() {
      this.openWdTimeLine = false;
    },
    setNotification(n) {
      this.selectedNotification = n;
    },
    setBSCrypto(t){
      this.b_s_crypto_title= t;
    },
    setbuyandsellview(w){
      this.buyandsellview = w;
    }
  },
});
