import { defineStore } from "pinia";
import { ref } from "vue";
import type {SellCryptoState} from "@/types/signed/Crypto";
import type {gatewayState, push} from "@/types/signed/wallet";

export const usePersistedCommonStore = defineStore(
  "PersistedCommonStore",
  () => {
    const bycott_modal = ref(false);
    const do_not_show_modal = ref(false);
    const sellCryptoState = ref<SellCryptoState>({
        state:false,
        depositTagMemo:undefined
    });
    const gatewayService = ref<gatewayState>({
        serviceName:'',
        factorId:0,
        trackId:0,
        date:0
    });
    const popupPushNotification = ref<push>(null);
    const onAuthConnectionErrorFlag = ref(false);
    const doNotShowgatewayPayInitModal = ref(false);
    return {
      bycott_modal,
      do_not_show_modal,
      sellCryptoState,
      gatewayService,
      popupPushNotification,
      onAuthConnectionErrorFlag,
        doNotShowgatewayPayInitModal
    };
  },
  {
    persist: true,
  },
);
