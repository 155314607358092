import { ref, computed } from "vue";
import { defineStore } from "pinia";
import type { userCredentials } from "@/types/sign";

export const useUserTokenStore = defineStore(
  "userTokenStore",
  () => {
    const user_credentials = ref<userCredentials>({
      user_id: 0,
      refresh_token: "",
      token: "",
      fp: "",
    });
    function setCredentials(input: userCredentials) {
      user_credentials.value = input;
    }
    function isLogined(): boolean {
      if (user_credentials.value.token === "") {
        return false;
      } else {
        return true;
      }
    }
    function logout(): void {
      user_credentials.value = {
        user_id: 0,
        refresh_token: "",
        token: "",
      };
    }
    return {
      user_credentials,
      setCredentials,
      isLogined,
      logout,
    };
  },
  { persist: true },
);
