<template>
  <div class="m-auto">
    <header class="d-flex align-items-center justify-content-between p-3">
      <h1 class="title" data-i18n="wallet">{{ $t("route_title." + $route.name) }}</h1>
      <div class="d-flex align-items-center" v-if="$route.name === 'market'">
        <img v-if="showFavorites" src="@/assets/images/svg/fav_fill2.svg" @click="toggleMarketFavSymbols" />
        <img v-else src="@/assets/images/svg/fav.svg" @click="toggleMarketFavSymbols" />
      </div>
      <router-link :to="{ name: 'order-history',params:{factor_type_query:(cmonStorage.getbuyandsellview === 'buy' ? FactorTypes.BUY_CRYPTO : FactorTypes.SELL_CRYPTO)} }" v-if="$route.name === 'buyandsell'">
        <img src="@/assets/images/svg/text.svg" alt="icon" />
      </router-link>
    </header>
    <main class="">
      <RouterView v-slot="{ Component }">
        <transition name="scale" mode="out-in">
          <suspense timeout="0">
            <template #default>
              <component :is="Component" />
            </template>
            <template #fallback>
              <basic-loading />
            </template>
          </suspense>
        </transition>
      </RouterView>
    </main>
    <UserNavigator />
  </div>
</template>

<script>
import UserNavigator from "@/components/UserNavigator.vue";
import BasicLoading from "@/views/loadings/basicLoading.vue";
import { storeToRefs } from "pinia/dist/pinia";
import { useCurrencyFavoritesStore } from "@/stores/useCurrencyFavoritesStore";
import {useCommonStore} from "@/stores/useCommonStore";
import { FactorTypes } from "@/types/signed/FactorDetails";
export default {
  name: "simpleHeaderLayout",
  components: { BasicLoading, UserNavigator },
  setup() {
    const { showFavorites } = storeToRefs(useCurrencyFavoritesStore());
    const cmonStorage = useCommonStore();
    const toggleMarketFavSymbols = () => {
      showFavorites.value = !showFavorites.value;
    };
    return {
      toggleMarketFavSymbols,
      showFavorites,
      cmonStorage,
      FactorTypes
    };
  },
};
</script>

<style scoped></style>
