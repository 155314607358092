import { ref, computed, inject } from "vue";
import type { AuthenticatesEntity, DashboardData } from "@/types/signed/dashboard";
import { defineStore } from "pinia";
import { useI18n } from "vue-i18n";
import type { ProfileData } from "@/types/signed/profile";
import SrvRequest from "@/composables/SrvRequest";
import type { wallet } from "@/types/signed/wallet";

const useDashboardStore = defineStore(
  "DashboardStore",
  () => {
    const { t } = useI18n();
    const userDashboardData = ref<DashboardData>();
    const getAuthoriseModesStr = (mode: number) => {
      return t("authenticates." + mode);
    };
    function test(): void {}
    function getData(): DashboardData {
      const authdata = userDashboardData?.value?.authenticates?.map((i) => {
        i.modeStr = getAuthoriseModesStr(i.mode);
        return i;
      });
      try {
        if (userDashboardData.value !== undefined) {
          userDashboardData.value.authenticates = authdata;
        }
      } catch (e) {}
      return userDashboardData.value;
    }
    return {
      userDashboardData,
      getData,
      test,
    };
  },
  {
    persist: true,
  },
);

const useProfileStore = defineStore(
  "ProfileStore",
  () => {
    const profileData = ref<ProfileData>();
    const { t } = useI18n();
    const getAuthoriseModesStr = (mode: number) => {
      return t("authenticates." + mode);
    };
    async function getData(): ProfileData {
      if (profileData.value === undefined) {
        profileData.value = (await SrvRequest().signedGet("/users/profile")).data;
      }
      const { userDashboardData } = useDashboardStore();
      userDashboardData.authenticates = profileData.value.profile.authenticats;
      const authdata = profileData?.value?.profile?.authenticats?.map((i) => {
        i.modeStr = getAuthoriseModesStr(i.mode);
        return i;
      });
      if (profileData.value?.profile.authenticats !== undefined) {
        profileData.value.profile.authenticats = authdata;
      }
      return profileData.value;
    }
    return {
      profileData,
      getData,
    };
  },
  {
    persist: true,
  },
);
const useUserRialWalletStore = defineStore("rialWalletStore", () => {
  const rialwallet = ref<wallet>();
  const getwallet = (): wallet => {
    return rialwallet.value;
  };
  return {
    rialwallet,
    getwallet,
  };
});

export default (useUserDataStore) => ({
  useDashboardStore,
  useProfileStore,
  useUserRialWalletStore,
});
