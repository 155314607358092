<template>
  <div class="main" id="index_loading_section">
    <section class="section1">
      <!-- banner slider -->
      <div>
        <div class="skeleton-banner"></div>
      </div>
      <div>
        <div class="skeleton-rate"></div>
      </div>
      <div class="auth-box">
        <div class="skeleton-auth"></div>
        <div class="skeleton-auth"></div>
      </div>
    </section>
    <section>
      <div class="skeleton-action"></div>
      <h1 class="skeleton-subtitle"></h1>
      <div class="services skeleton-container">
        <div class="skeleton-service"></div>
        <div class="skeleton-service"></div>
        <div class="skeleton-service"></div>
        <div class="skeleton-service"></div>
        <div class="skeleton-service"></div>
        <div class="skeleton-service"></div>
        <div class="skeleton-service"></div>
        <div class="skeleton-service"></div>
        <div class="skeleton-service"></div>
        <div class="skeleton-service"></div>
        <div class="skeleton-service"></div>
        <div class="skeleton-service"></div>
      </div>
    </section>
  </div>
</template>

<style scoped>
.main {
  padding: 15px;
  background: white;
}
.dark .main {
  padding: 15px;
  background: #1c2029;
}
.section1 {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
/* Skeleton styles */

.skeleton-banner,
.skeleton-rate,
.skeleton-info,
.skeleton-action,
.skeleton-subtitle,
.skeleton-auth,
.skeleton-service {
  border-radius: 8px;
  background-color: #d5d4d4;
  animation: skeletonLoading 1.5s infinite ease-in-out;
}

.skeleton-banner {
  width: 100%;
  height: 200px;
}
.auth-box {
  display: flex;
  gap: 10px;
  margin-bottom: 1rem;
}
.skeleton-auth {
  width: 50%;
  height: 60px;
}
.skeleton-rate {
  width: 100%;
  height: 60px;
}

.skeleton-info {
  width: 100%;
  height: 20px;
  margin-bottom: 1rem;
}

.skeleton-action {
  width: 100%;
  height: 30px;
  margin-bottom: 1rem;
}

.skeleton-subtitle {
  width: 100px;
  height: 30px;
  margin-bottom: 1rem;
}

.skeleton-service {
  width: 100px;
  height: 100px;
  margin-bottom: 1rem;
}
.skeleton-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 15px;
  padding: 10px;
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
}
@keyframes skeletonLoading {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.9;
  }
  100% {
    opacity: 0.5;
  }
}
</style>
