<template>
  <div class="module warning" v-if="bycott_modal">
    <div class="backDrop"></div>
    <div class="p-3 module-content">
      <h6 class="lg-mainColor-text fw-bold border-bottom py-3 d-flex">
        <img src="@/assets/images/svg/warning.svg" width="20" class="mx-1" />
        <p>{{ $t("important") }}</p>
      </h6>
      <p class="md-text text-justify">
        {{ $t("chooseNetworkTypeDes") }}
      </p>
      <div class="d-flex align-items-center justify-content-between mt-3">
        <button class="mainButton mx-1" @click="closeModal(false)">{{ $t("iRealized") }}</button>
        <button class="mainButton-outline dontShow mx-1" v-if="!do_not_show_modal" @click="closeModal(true)" data-i18n="dontShowItAnymore">{{ $t("dontShowItAnymore") }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { useCommonStore } from "@/stores/useCommonStore";
import { storeToRefs } from "pinia";
import { usePersistedCommonStore } from "@/stores/usePersistedCommonStore";

export default {
  name: "byCottModal",
  setup() {
    const { bycott_modal, do_not_show_modal } = storeToRefs(usePersistedCommonStore());
    const closeModal = (do_not_show = false) => {
      if (do_not_show) {
        do_not_show_modal.value = true;
      }
      bycott_modal.value = false;
    };
    return {
      closeModal,
      bycott_modal,
      do_not_show_modal,
    };
  },
};
</script>

<style scoped></style>
