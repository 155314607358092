import {Capacitor} from "@capacitor/core";
import {App} from '@capacitor/app';
import type {ImageOptions, Photo} from '@capacitor/camera';
import {Camera, CameraDirection, CameraResultType, CameraSource} from '@capacitor/camera';

const isNativeIphone = ()=>{
    if(Capacitor.isNativePlatform() && Capacitor.getPlatform() === 'ios'){
        return true;
    }else{
        return false;
    }
}
const isNativeApp = ()=>{
    return Capacitor.isNativePlatform();
}
const getPlatform = ()=>{
    return Capacitor.getPlatform().toLowerCase();
}
const isAndroid = ()=>{
    if(getPlatform() === 'android'){
        return true;
    }else {
        return false;
    }
}
const isPWA = ()=>{
    if(['web','pwa'].includes(getPlatform())){
        return true;
    }else {
        return false;
    }
}
export const getAppVersion = async ()=>{
    if(Capacitor.isNativePlatform()) {
        const info = await App.getInfo();
        return info.version;
    }else{
        return "PWA";
    }
}
const takePicture = async () :Photo => {
    try{
        const image = await Camera.getPhoto({
            quality: 90,
            allowEditing: false,
            resultType: CameraResultType.Uri,
            source:CameraSource.Camera,
            direction:'REAR',
            saveToGallery:true,
            correctOrientation:true,
            width:7680,
            height:1536
        });
        // image.webPath will contain a path that can be set as an image src.
        // You can access the original file using image.path, which can be
        // passed to the Filesystem API to read the raw data of the image,
        // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
        return image;
    }catch (e) {
        console.error(e);
        return null;
    }
};
export default (CapasitorMacro) => ({
    isNativeIphone,
    getAppVersion,
    isNativeApp,
    getPlatform,
    isAndroid,
    isPWA,
    takePicture
});